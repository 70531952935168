// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('datatables.net-bs4')                     // importanto data-tables

import $ from 'jquery';
global.$ = jQuery;
import 'bootstrap';
import '../stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
document.addEventListener('turbolinks:load', () => {
  $('.table').DataTable({
    ordering: true,
    pageLength: 100,
    language: {
      search: '',
      searchPlaceholder: 'Search...',
      lengthMenu: "_MENU_",
      info: 'page _PAGE_ of _PAGES_'
    }
  })
})

$(document).on('scroll', function(){
  if(window.pageYOffset > 60) {
    $('.navbar').addClass('bg-light');
    $('.navbar img').css('width', 50);
  } else {
    $('.navbar').removeClass('bg-light');
    $('.navbar img').css('width', 200);
  }
});